@import '../shared';

.about .page-top {
  padding-top: 60px;
}

.about-title {
  margin-top: 40px;
}

.about-top {
  padding-bottom: $normalDistance;
  background-repeat: no-repeat;
  background-position: right;
  padding-top: $normalDistance;
  background-size: cover;
}

.about-logo {
  width: 100px;
  height: auto;
  margin-right: 100px;

  &:last-child {
    margin-right: 0;
  }
}

.photo-credit {
  margin-right: 20px;
}

.margin-bottom-me-about-page {
  margin-bottom: 60px;
}

.about-circle {
  position: absolute;
  right: 0px;
  z-index: 1;
  bottom: -200px;
}

.gif-container {
  position: relative;
  z-index: 2;
}

.about-squiggle {
  position: absolute;
  left: -200px;
  bottom: -150px;
}

.about-link {
  display: block;
  margin-bottom: 10px;
  color: #39495C;

  &:hover {
    color: black;
    text-decoration: none;
  }
}

.about-photo {
  width: 100%;
  height: auto;

  &.about2 {
    width: 50%;
    margin-bottom: 30px;
  }
}

.about-2-container {
  margin-bottom: 60px;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: $xtra-small-screens) {
  .about-logo {
    margin-right: 20px;
    width: 80px;
  }

  .about .page-top {
    background-position: 90% 0%;
    background-size: inherit;
  }

  .about-circle {
    position: absolute;
    right: 0px;
    z-index: 1;
    bottom: -250px;
  }

  .about-squiggle {
    position: absolute;
    left: -100px;
    top: 1050px;
    width: 220px;
  }

  .mobile-margin-top {
    margin-top: 60px;
  }

  .mobile-margin-top-bit-more {
    margin-top: 80px;
  }

  .margin-bottom-me-about-page {
    margin-bottom: 0px;
  } 

  .about-2-container {
    margin-bottom: 30px;
  }

  .about-photo {
    &.about2 {
      width: 100%;
      margin-bottom: 30px;
      margin-left: 0px;
      padding-left: 20px;
    }

    &.about3 {
      margin-bottom: 100px;
    }
  }


}