@import '../shared';

$pro-content-height: 700px;

$empathyColor: #FF9675;
$inspiredColor: #35B9A1;
$focusColor: #6452F6; 

.tools {
  .page-top {
    padding-top: 0px;
  }
}

.tools-top {
  padding-bottom: $normalDistance;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 200px;
}

.tools-subpage-copy-container {
  padding: 40px 60px 40px 40px;
  border-left: 6px solid $empathyColor;
  background-color: #FEF9F9;
  margin-bottom: 40px;
}

.thanks {
  display: block;
  text-align: right;
  font-weight: 500;
  font-size: 16px;
  margin-top: 100px;

  &.hide {
    display: none;
  }
}

.get-inspired {
  .tools-subpage-copy-container {
    border-left: 6px solid $inspiredColor;
    background-color: #F6FCF8;
  }
}

.build-empathy {
  .page-top {
    background-color: $empathyColor;
    padding-top: 0px;
  }

  .tools-top {
    background-size: cover;
  }

  .tools-subpage-hero {
    width: 500px;
    height: auto;
  }

}

#build-empathy-link {
  color: #FF9675;
  font-weight: 500;
  transition: all 0.35s;

  &:hover {
    text-decoration: none;
    color: #AF5437;
  }
}

#get-inspired-link {
  color: #35B9A1;
  font-weight: 500;
  transition: all 0.35s;

  &:hover {
    text-decoration: none;
    color: #13846F;
  }
}

#focus-challenge-link {
  color: #6452F6;
  font-weight: 500;
  transition: all 0.35s;

  &:hover {
    text-decoration: none;
    color: #231972;
  }
}

.focus-challenge {

  .tools-top {
    background-size: cover;
  }

  .page-top {
    background-color: $focusColor;
    padding-top: 0px;
  }

  .tools-subpage-hero {
    width: 427px;
    height: auto;
  }

  .tools-subpage-copy-container {
    border-left: 6px solid $focusColor;
    background-color: #F6F5FE;
  }

  .tools-subpage-list li::before {
    color: $focusColor; /* Change the color */
  }
}

.design-principle-cards {
  .tools-subpage-hero {
    max-width: 400px;
  }
}

.get-inspired {
  .page-top {
    background-color: $inspiredColor;
    padding-top: 0px;
  }  

  .tools-top {
    background-size: cover;
  }

  .tools-subpage-list li::before {
    color: $inspiredColor; /* Change the color */
  }

  .tools-subpage-hero {
    width: 100%;
    height: auto;
  }

}

.tools-subpage-title {
  margin: 100px 0;
}

.tools-subpage-content {
  margin-bottom: $normalDistance;
}

.tools-subpage-list {
  list-style-type: none;

  li {
    margin-top: 10px;
  }

  li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: $empathyColor; /* Change the color */
    font-weight: 500; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }
}

.tools-rightside-header {
  font-weight: 500;
  margin-bottom: 10px;
}

.tools-subpage-header {
  font-weight: 500;
  margin-bottom: 20px;
}

.tools-rightside-header:not(:first-child) {
  margin-top: 40px;
}

.less-wide-text {
  max-width: 500px;
}

.tools-top-squiggle {
  position: absolute;
  left: -200px;
  bottom: -200px;
}

.tools-subpage-hero {
  position: absolute;
  right: 0px;
  bottom: -200px;
}

.sprint-canvas {
  .tools-subpage-hero {
    max-width: 600px;
    max-width: 100%;
    top: 0px;
    right: 0px;
  }
}

.sprint-focus-cards {
  .tools-subpage-hero {
    max-width: 90%;
    top: 0px;
    right: 0px;
  }
}

.user-context-cards {
  .tools-subpage-hero {
    max-width: 700px;
    max-width: 100%;
    top: 100px;
    right: 0px;
  }
}

.design-principle-cards {
  .tools-subpage-hero {
    max-width: 550px;
    max-width: 90%;
    right: 0px;
    top: 20px;
  }
}

.tools-subpage-bottom-section {
  background-color: $purple2;
  margin-top: $normalDistance;
  padding-top: $normalDistance;
  padding-bottom: $normalDistance;
}

.strong-text-block {
  font-weight: 500;
  margin-bottom: 20px;
}

.animated-gif {
  width: 100%;
  height: auto;
  position: relative;
}

.flipkart-text {
  max-width: 140px;
}

.tools-logo {
  width: 100px;
  display: block;
  margin: 20px 0;

  &.flipkart-logo {
    margin-top: 60px;
    width: 170px;
  }

  &.google-pay-logo {
    margin-top: 40px;
    margin-bottom: 30px;
  }

  &.airtel-logo {
    margin-top: 15px;
    //margin-bottom: 20px;
    width: 220px;
  }
}

.tools-mobile-overlay {
  display: none;
}

.logo-caption {
  width: 200px;

  &.airtel {
    margin-top: -10px;
  }

}

.tools-middle-squiggle {
  position: absolute;
  width: 100%;
  right: -200px;
  bottom: -150px;
  max-width: 400px;
  height: auto;
}

.tools-subpage-icon {
  width: 30px;
  height: auto;
}

.yellow-square {
  position: absolute;
  left: -50px;
  top: -50px;
}

.tools-tool-arrow {
  margin-left: 10px;
  width: 17px;
  height: auto;
}

.download-modal {
  .privacy-text {
    margin-bottom: 60px;
    a {
      color: white;
    }
  } 
}

.tools-tool-image {
  width: 220px;
  height: auto;

  &.design-principle-cards {
    width: 258px;
  }

  &.user-context-cards-image {
    width: 246px;
  }
}

.inspiration-tools-main-image {
  width: 100%;
  max-width: 500px;
  height: auto;
  cursor: pointer;
}

.tools-tools-container {
  vertical-align: top;
  position: relative;
  max-width: 300px;
  display: inline-block;
  margin-right: 40px;
  cursor: pointer;
  transition: all 0.35s;
  margin-top: 20px;

  &.sprint-focus-cards {
    // margin-top: -35px;
  }

  &.user-context-cards {
    // margin-top: -25px;
  }

  &.inspiration-tool {
    // margin-top: -25px;
  }

  &:hover {
    color: black;
  }
}

.hover-image {
  position: absolute;
  z-index: 20;
  width: 40px;
  height: auto;
  top: 25%;
  right: 15%;

  &.second-position {
    top: 40%;
  }
}

.more-tools-row {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
  transition: all 0.5s;

  a {
    color: orange;
  }

  &:hover {
    color: black;
  }

  .more-tools-column {
    flex: 1;
  }

  .text-col {
    padding-left: 20px;
  }

  .icon-containing-col {
    max-width: 50px;
  }
}

.lmm {
  .show-if-mobile {
    display: none;
  } 
}

.arrow-link-row {
  display: inline-flex;
  align-items: center;
  max-width: 260px;

  .text-6 {
    font-weight: 500;
  }

  .arrow-link-col {
    flex: 1;

    &:first-child {
      min-width: 140px;
    }
  }
}

.pro-nav-top-buttons-container {
  display: flex;
  margin-bottom: 15px;
}

.pro-nav-top-button {
  flex: 1;
  padding: 20px;
  color: #35B9A1;
  border: 1px solid #35B9A1;
  text-align: center;
  border-radius: 2px;
  transition: all 0.35s;

  &:hover {
    text-decoration: none;
    color: #197867;
  }

  &:first-child {
    margin-right: 10px;
  }

  &.selected {
    background-color: #35B9A1;
    color: white;    
  }
}

.pro-nav-item {
  background-color: #E9F9EF;
  padding-top: 5px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 2px;
  transition: all 0.3s;
  display: block;
  color: #39495C;



  &.selected {
    background-color: #C8F0D6;
  }

  &:hover {
    background-color: #C8F0D6;
  }

}

.pro-icon {
  width: 32px;
  height: auto;
  display: inline-block;
  margin: 10px 20px;
}

.pro-container {
  margin-bottom: $normalDistance;
}

.pro-content-container {
  background-repeat: no-repeat;
  background-size: auto;
  background-position: right 100px top 80px;
  padding-right: 50px;
  height: $pro-content-height;
  scroll-snap-align: start;
}

.pro-content-text {
  width: 50%;
  padding-left: 10%;
  padding-top: 15%;
  font-weight: 400;
}

.pro-logo {
  display: block;
  margin-bottom: 40px;

  &.tiktok {
    max-width: 80px;
    height: auto;
  }

  &.kormo {
    min-width: 100px;
    height: auto;
  }
}

.scroll-within {
  width: 100%;
  height: $pro-content-height; 
  overflow: hidden;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  position: relative;

  .text-3 {
    font-weight: 500;
  }
}

.grid-container {
  max-width: 1200px;
  margin: 0 auto;
}

.video-iframe {
  // position: absolute;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  width: 1000px;
  height: 700px;
}

.page-dots-container {
  position: absolute;
  top: 270px;
  right: 20px;
  width: 50px;
  height: 100px;

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #C4C8CE;
    margin: 20px auto;
    transition: all 0.35s;

    &.on {
      background-color: #39495C;
    }
  }
}

.ethan-test {
  width: 40%;
  background-color: green;
  margin-right: 20px;
  margin-bottom: 20px;
  height: 200px;
  display: inline-block;

  &.test2 {
    height: 150px;
  }

  &.test3 {
    height: 170px;
  }
}


.modal-dialog {
  max-width: none;
  width: 90%;
  height: 60%;
  margin-left: auto;
  margin-right: auto;
  // height: 50%;
}


@media screen and (min-width: 1200px) {
  .modal-dialog {
    max-width: none;
    width: 1200px;
    height: 800px;
  }

  .sprint-canvas {
    .tools-subpage-hero {
      top: 40px;
      max-width: 110%;
    }
  }

  .sprint-focus-cards {
    .tools-subpage-hero {
      top: 40px;
    }
  }
}

@media (max-width: $large-screens) {
  .tools-middle-squiggle {
    width: 350px;
    top: 40px;
    height: auto;
    left: -200px;
    bottom: 50px;
    position: absolute;
    z-index: 10;
  }

  .airtel-gif {
    position: relative;
    z-index: 20;
  }

  .tools-tools-container {
    vertical-align: top;
    width: 40%;
    margin-right: 0;
    margin-bottom: 40px;

    &:nth-child(odd) {
      margin-right: 20px;
    }

    .tools-tool-image {
      width: 100%;
    }

    &.sprint-focus-cards, &.user-context-cards, &.inspiration-tool {
      // margin-top: 0;
    }

  }

  .airtel-mobile-spacing {
    margin-right: 40px;
    text-align: left;
    margin-left: 30%;

    .tools-logo {
      margin-top: 20px;
    }

    .logo-caption {
      width: 100%;
    }
  }

  .gpay-mobile-spacing {
    margin-top: 40px;
    margin-left: 40px;
  }

  .yellow-square {
    position: absolute;
    left: 40px;
    top: -20px;
    height: 240px;
    width: 300px;
  }

  .mobile-align-right {
    text-align: right;

    .tools-logo {
      display: inline-block;
    }
  }

  .tools-top-squiggle {
    position: absolute;
    bottom: -250px;
  }

  .tools-logo.flipkart-logo {
    margin-top: 20px;
  }

  .flipkart-text {
    max-width: 100%;
  }

  .activity-page {
    .tools-top {
      // padding-top: 80px;
    }

    .tools-subpage-title {
      margin: 20px 0;
    }
  }

  .tools-subpage-top {
    padding-top: 0px;  
  }

  .lmm .tools-subpage {
    .tools-subpage-title {
      margin-top: 160px;
      margin-bottom: 40px;
    }

    .tools-subpage-subtitle {
      margin-bottom: 160px;
    }

    .tools-subpage-hero {
      position: absolute;
      width: 60%;
      top: -120px;
      right: 0;
    }

    .tools-subpage-hero.get-inspired-phone {
      position: absolute;
      width: 100%;
      top: -80px;
      right: 0;
    }
  }

  .lmm {
    .tools-subpage-hero {
      position: relative;
      margin-top: 40px;
      width: 100%;
      right: -50px;
      bottom: -100px;
    }

    .user-context-cards, .design-principle-cards {
      .tools-subpage-hero {
        position: relative;
        margin-top: -50px;
        width: 100%;
        right: 0px;
        bottom: 0;
      } 

      .tools-subpage-hero-container {
        padding-right: 0;
        overflow: initial;
      }

      .tools-subpage-bottom-section {
        margin-top: 50px;
      }
    }
    
    .design-principle-cards {
      .tools-subpage-hero {
        margin-top: 0px;
      } 
    }

    .tools-subpage-bottom-section {
      margin-top: -50px;
    }

    .tools-subpage-hero-container {
      padding-right: 0;
      overflow-x: hidden;
    }
  }
}

@media (max-width: $small-screens) {
  .lmm {
    .tools-subpage-hero {
      position: relative;
      margin-top: 40px;
      width: 100%;
      right: -50px;
      bottom: -100px;
    }
  }

  .tools-mobile-overlay {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($color: #ffffff, $alpha: 0.95);
    z-index: 18;
    align-items: center;
    justify-content: center;
    display: none;

    .overlay-content {
      font-size: 24px;
      font-weight: 500;
      padding: 100px;
    }
  }
}


@media (max-width: $large-screens) {
  
  .lmm {
    .hide-if-mobile {
      display: none;
    }

    .show-if-mobile {
      display: block;
    }

    .pro-nav-top-buttons-container.show-if-mobile {
      display: flex;
    }
  }

  .page-top-space.pro-container {
    margin-top: 90px;
  }

  .pro-nav-top-buttons-bottom {
    margin-top: 20px;
  }

  .section-container {
    max-height: 0px;
    overflow: hidden;
    transition: all 0.35s;

    &.open {
      transition: all 0.35s;
      max-height: 3000px;
    }
  }

  .scroll-within {
    height: auto; 
    scroll-snap-type: y mandatory;
    overflow: visible;
  }

  .pro-logo {
    max-width: 70px;
    margin-bottom: 20px;
  }

  .pro-content-container {
    background-size: 280px;
    background-position: right 0px top 20px;
    height: 340px;
    margin-bottom: 100px;
    // height: 20px;
    // overflow: hidden;
  }

  .pro-content-text {
    width: 100%;
    padding: 60px 120px 20px 20px;
    font-weight: 400;
    font-size: 16px;
  }

  .products {
    .pro-content-text {
      padding-top: 20px;
    }
  }

  .pro-nav-item {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .pro-nav-item:not(:first-child) {
    margin-top: 0px;
  }
}

@media (min-width: $large-screens) and (max-width: $extra-large-screens) {
  .pro-content-container {
    background-size: 500px;
    background-position: right 60px top 60px;
    height: 700px;
    margin-bottom: 100px;
  }

  .pro-content-text {
    width: 100%;
    padding: 200px 50% 20px 20px;
    font-weight: 400;
    font-size: 20px;
  }
}
