@import '../shared';

#footer {
  position: relative;
  z-index: 20;
  overflow: hidden;

  a {
    color: #F0EEFE;
    font-weight: 400;

    &.bold-text {
      font-weight: 500;
    }
    
    &:hover {
      color: $whiteHover;
    }
  }
}

.lmm-modal-footer {
  background-color: #1D1A45;
}

.footer-section-1 {
 background-color: $purple2;
 background-repeat: no-repeat;
 background-position: right bottom;
 padding: $normalDistance;
}

.footer-activity-box {
  width: 30%;
  min-width: 120px;
  max-width: 224px;
  background-color: white;
  border: 1px solid $purple1;
  padding: 40px;
  height: 280px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  margin-right: 20px;
  transition: all 0.35s;

  &:hover {
    background-color: $purpleHover;

    .bottom-title, .activity, .length-of-time {
      color: white;
    }

    .arrow-purple-right {
      filter:  brightness(0) invert(1);
    }
  }
}

.length-of-time {
  color: $purple3;
}

.activity {
  color: $purple1;
}

.contact-link {
  cursor: pointer;
  transition: all 0.35s;

  &:hover {
    color: #C2BEE2;
  }
}

.arrow-purple-right {
  bottom: 40px;
  right: 20px;
  width: 32px;
  height: auto;
  position: absolute;
}

.bottom-title {
  position: absolute;
  bottom: 35px;
  left: 40px;
  max-width: 100px;
  color: $purple1;
}

.footer-bottom-row-container {
  background-color: $purple4;
  padding: $normalDistance;
  color: white;
}

.footer-email-input {
  padding: 20px;
  width: 300px;
  margin-right: 20px;
  border: none; /* <-- This thing here */
  border:solid 1px #ccc;
  border-radius: 2px;
}

.logo {
  margin-left: 40px;
  display: inline-block;
  margin-top: 40px;
}

.ideo-logo {
  width: 77px;
}

.gates-logo {
  width: 120px;
}

.google-logo {
  width: 80px;
}

.contact-email-error {
  color: red;
  display: inline-block;
  margin-left: 20px;
  display: none;

  &.show {
    display: inline-block;
  }
}

.sprint-form-submit.hide {
  display: none;
}

.contact-thank-you {
  text-align: right;
  display: none;

  &.show {
    display: block;
  }
}

.fine-print {
  font-size: 8px;
  margin-top: 20px;
  display: none;
}

.footer-copy {
  max-width: 320px;
}

.footer-link {
  display: block;
  color: white;
  transition: all 0.35s;

  &:hover {
    text-decoration: none;
    color: $whiteHover;
  }
}

.footer-join-text {
  line-height: 20px;
}

.footer-links-yo {
  padding-right: 0;
}


@media (max-width: $extra-large-screens) {
  .footer-activity-box {
    width: 100%;
    margin-right: 0;
    max-width: 100%;
    margin-bottom: 20px;
    height: 180px;
    padding: 20px;
  }

  .bottom-title {
    max-width: 300px;
  }
}

@media (max-width: $large-screens) {
  .footer-email-input {
    width: 100%;
  }

  .footer-sponsor-links {
    text-align: left;
    display: flex;
    justify-content: center; 
    margin-top: 40px;

    .gates-footer-link {
      margin: 0 65px;
      display: inline-block;
    }

    .logo {
      margin: 0;
    }

    // .logo:last-child {
    //   margin-right: 0px;
    // }
  }

  .footer-bottom-row-container {
    padding: 60px 0px 0px 0px;
  }

  .footer-subtitle {
    margin-bottom: 60px;
  }

  .mobile-footer-top {
    padding-bottom: 40px;

    .join-button {
      width: 100%;
      text-align: center;
      font-weight: 600;
      margin-top: 10px;
    }
  }

  .mobile-footer-bottom {
    background-color: #3A3582;
    // background-color: #443E8F;
    padding-bottom: 100px;
  }

  .footer-activity-box {
    width: 100%;
    margin-right: 0;
    max-width: 100%;
    margin-bottom: 20px;
    height: 180px;
    padding: 20px;
  }

  .bottom-title {
    position: absolute;
    bottom: 20px;
    left: 20px;
    max-width: 200px;
    color: #6452F6;
  }

  .footer-bottom-stuff {
    margin-top: 60px;
  }

  .logo {
    max-width: 50px;
  }

  .footer-links-yo {
    padding-right: 0;
  }

  .footer-section-1 {
    padding: 100px 20px;
  }
}