@import '../shared';

.sprint-tools-section {
  background-color: #F2F0FE;
  padding-top: $normalDistance; 
  padding-bottom: $normalDistance;
  margin-top: $normalDistance;
  overflow: hidden;
}

.menu-col {
  position: relative;
  z-index: 20;
}

.design-sprint-nav {
  background-color: white;
  width: 300px;
  height: 92px;
  padding: 20px;
  padding-right: 60px;
  margin-bottom: 10px;
  transform-origin: bottom left;
  cursor: pointer;
  font-size: 32px;
  display: flex;
  position: relative;

  .tools-subpage-icon {
    width: 30px;
    height: 30px;
    margin: 10px 20px 0 0;
  }

  .dom-text {
    margin-bottom: 10px;
  }

  .sub-text {
    // visibility: hidden;
    font-size: 16px;
  }

  .button-text-container {
    flex: 1;
  }

  &.on {
    background-color: #6452F6;
    color: white;

    .sub-text {
      // visibility: visible;
    }
  }
}

.box-container {
  position: relative;
  width: 500px;

}

.box-back {
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  bottom: 0;  
}

.box-content {
  width: 300px;
  height: auto;
  position: absolute;
  left: 25%;
  top: 200px; 
  
  &.design {
    width: 800px;
    left: -20%;
  }

  &.test {
    width: 500px;
    left: 5%;
  }

}

.box-front {
  left: -75px;
  bottom: 0px;
  width: 112%;
  height: auto;
  position: absolute;
  cursor: pointer;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: $large-screens) {
  .tools-images {
    display: none;
  }

  .box-content {
    width: 90%;
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
    height: auto;
    position: relative;
    left: 0;
    top: unset;

    &.design {
      left: 0;
      width: 100%;
    }

    &.test {
      left: 0;
      width: 100%;
    }

  }

  .box-content-container {
    text-align: center;
    margin-bottom: 60px;
  }

}