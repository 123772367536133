@import '../shared';

.opp-box-1-container {
  display: inline-block;
  width: 60%;
  position: relative;
  z-index: 10;
}

.opp-box {
  padding: 60px 40px;
  text-align: center;
  display: inline-block;
}

.opp-box-1-shadow {
  position: absolute;
  top: -40px;
  left: -40px;
  background-size: cover;
  height: 200px;
  width: 100%;
}

.opp-box-1 {
  background-color: white;
  color: $textColor1;
  position: relative;
}

.opp-box-2-container {
  display: inline-block;
  width: 70%;
  position: relative;
  margin-top: 20px;
}

.opp-box-2 {
  background-color: #FCF0F0;
  color: $textColor1;
  text-align: left;
  padding-left: 40%;
  margin-bottom: 100px;
  position: relative;
}

.opp-box-2-human {
  position: absolute;
  bottom: 0;
  width: 55%;
  height: auto;
  left: -100px;
}

.opp-box-2-human-mobile {
  display: none;
}

.opp-box-2-human-mobile-container {
  display: none;
}

.opp-box-2-squiggle {
  position: absolute;
  bottom: 40px;
  width: 40%;
  height: auto;
  right: 100px;
}

.opp-box-3-container {
  display: inline-block;
  width: 80%;
  position: relative;
  margin-top: 100px;
}

.opp-box-3-shadow {
  position: absolute;
  right: -40px;
  bottom: -40px;
  width: 140px;
  height: auto;
}

.opp-box-3-sun-mobile {
  display: none;
}

.opp-box-3-human {
  position: absolute;
  top: -325px;
  width: 120%;
  height: auto;
  left: -20px;
}

.opp-box-3-human-mobile {
  display: none;
}

.opp-box-3 {
  background-color: $textColor1;
  color: white;
  position: relative;
  text-align: left;
}

.opp-row {
  margin-top: 200px;
}

.opp-title-1 {
  margin-bottom: 20px;
}

.opp-small-text {
  text-align: left;
}

.remove-left-padding {
  padding-left: 0;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: $extra-large-screens) {

  .opp-box-1-container {
    width: 100%;
  }

  .opp-box-2-container {
    width: 100%;
    overflow: hidden;
    margin-top: 15px;
  }

  .opp-box-3-container {
    margin-top: 0px;
    width: 100%;
    //overflow: hidden;
    margin-bottom: 100px;
  }

  .opp-box-3 {
    padding-left: 30%;
    position: relative;
    z-index: 20;
  }

  .remove-left-padding {
    padding-left: 15px;
  }

  .opps-top {
    margin-top: 100px;
  }

  .opp-box-2 {
    padding-left: 40px;
    padding-right: 30%;
    margin-bottom: 10px;
  }

  .opp-row {
    margin-top: 140px;
  }

  .opp-box-2-human {
    display: none;
    left: unset;
    right: -25px;
    width: 150px;
    height: auto;
    bottom: 20px;
  }

  .opp-box-2-human-mobile {
    width: 200px;
    height: auto;
    position: absolute;
    display: block;
    left: unset;
    right: -70px;
    bottom: 0;
  }

  .opp-box-2-human-mobile-container {
    width: 150px;
    height: 300px;
    position: absolute;
    display: block;
    left: unset;
    right: 0px;
    bottom: 17px;
    overflow: hidden;
  }

  .opp-box-3-human {
    display: none;
  }

  .opp-box-3-human-mobile {
    display: block;
    position: absolute;
    top: unset;
    width: 260px;
    height: auto;
    left: -70px;
    bottom: -110px;
    z-index: 22;
  }

  .opp-box-3-shadow {
    right: -15px;
    bottom: -40px;
    width: 100px;
    height: auto;
    z-index: 5;
  }

  .opp-box-3-sun-mobile {
    display: block;
    position: absolute;
    top: unset;
    width: 160px;
    height: auto;
    left: -70px;
    top: -60px;
    z-index: 5;
  }
}