@import '../shared';

.sprint-hmw-container {
  width: 300px;
  border-left: 0px solid #F0EEFE;
  border-right: 1px solid #F0EEFE;
  margin: 60px 0;
  padding: 40px;
  display: inline-block;
  vertical-align: top;
}

.horizontal-slider-container {
  width: 100%;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.horizontal-slider {
  width: 13000px;
}

.run-a-sprint-button-container {
  .button1 {
    margin-top: 0;
  }
}
 
.digital-sprint-form-container {
  margin-top: $normalDistance;
}

.digital-sprint-form-container-container {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s;
  background-color: #D9D5F2;

  &.open {
    max-height: 1000px;
  }
}

.sprint-form-submit {
  margin-top: 40px;
  float: right;

  &.hide {
    display: none;
  }
}

.horizontal-slider-cover {
  position: absolute;
  width: 100%;
  height: 300px;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: $xtra-small-screens) {
  .horizontal-slider-container {
    // display: none;
  }
  .horizontal-slider-cover {
    // display: none;
  }

  .digital-sprint-form-container-container.open {
    max-height: fit-content;
  }

}