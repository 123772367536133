@import '../shared';

.audio-clips-title-container {
  margin-top: $normalDistance;
}

.audio-row {
  margin-top: $normalDistance;
}

.react-audio-player  {
  outline: 0;
}

.audio-text-col {
  margin-top: $normalDistance;
}

.audio-human {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  height: auto;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: $large-screens) {
  .audio-human {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    height: auto;
  }

  .audio-clips {
    .audio-row {
      margin-top: 40px;
      text-align: center;

      .text-right {
        text-align: center !important;
      }

      .text-left {
        text-align: center !important;
      }

      .thin-text {
        max-width: 100%;
      }

      .margin-top-me-big {
        margin-top: 40px;
      }

    }

    .audio-text-col {
      margin-top: 20px;
    }
  }

}

