@import '../shared';

.top-nav {
  position: fixed;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 0;
  background-color: white;
  z-index: 201;
  padding: 0px 0;
  color: $textColor1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

  .home-link {
    font-size: 18px;
    font-weight: 400;
  }

  .top-nav-link {
    font-size: 14px;
    font-weight: 500;
  }


  &.home {
    background-color: $purple1;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.05);
    
    a {
      color: white;
    }

    a:hover {
      color: $whiteHover;
    }

    .top-nav-item {
      border-top: 4px solid $purple1;
    }

    .home-link {
      opacity: 0;
    }

  }

  .top-nav-mobile {
    display: none;
    height: 70px;
  }

  .top-nav-item {
    display: inline-block;
    margin-left: 40px;
    padding: 16px 0;
    border-top: 4px solid white;

    &.on {
      border-top: 4px solid $purple1;
    }
  }

  a {
    color: $textColor1;
  }

  a:hover {
    color: black;
    text-decoration: none;
  }

}

.mobile-nav-arrow {
  width: 23px;
  height: auto;
  display: inline-block;
  margin-top: 40px;
}

.mobile-nav {
  display: none;
  position: fixed;
  z-index: 100;
  background-color: #5348C0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &.open {
    display: block;
  }
}

.home-close-burger {
  width: 25px;
  height: auto;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 20;
}

a.mobile-nav-section:hover {
  color: white;
}

.mobile-nav-section {
  height: 48%;
  padding: 50px;
  display: block;
  color: white;
  position: relative;

  &.mobile-nav-top {
    background-color: #6452F6;
  }

  &.mobile-nav-bottom {
    background-color: #5348C0;
  }

  .mobile-nav-image {
    position: absolute;
    right: 10px;
    bottom: 0;
    width: 140px;
    height: auto;

    &.bottom {
      width: 240px;
      // color: red;
    }
  }
}

.mobile-nav-title {
  font-weight: 15px;
  color: #39495C;
  padding: 12px 15px;
  display: block;

  &:hover {
    color: black;
  }
}

.top-nav-mobile {
  display: none;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: $medium-screens) {
  .top-nav {
    display: none;
  }
  .top-nav-mobile {
    display: block;
    position: fixed;
    width: 100%;
    height: 70px;
    color: white;
    z-index: 30;
    background: white;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

    &.home {
      background-color: $purple1;
      box-shadow: none;
      
      .mobile-nav-title {
        display: none;
      }
    }

    &.not-home {
      .home-burger {
        filter: brightness(0.2);
      }
    }


    .home-burger {
      z-index: 40;
      width: 25px;
      height: auto;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
}
