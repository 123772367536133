@import '../shared';

.focus-areas-container {
  background-color: $purple2;
  padding-top: $normalDistance;
  padding-bottom: $normalDistance;
}

.focus-area-nav-box {
  background: #FFFFFF;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  // width: 200px;
  // height: 200px;
  width: 18%;
  max-width: 200px;
  height: 200px;
  margin: 0 5px;
  display: inline-block;
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
  opacity: 1;
  transition: all 0.5s;

  &.faded {
    opacity: .3;
    transition: all 0.5s;
  }
}

.focus-area-title {
  margin: 50px 50px 30px 0;
}

.focus-area-area {
  height: 0px;
  width: 1040px;
  background-color: white;
  margin: 10px auto;
  padding: 0 0 0px 0;
  transition: all 0.5s;
  overflow: hidden;
  // padding: 100px;

  &.open {
    height: 610px;
    transition: all 0.5s;
  }
}

.focus-area-container {
  height: 100%;
  padding-bottom: 100px;
  background-position: 50%;

  &.navigation-focus-area {
    background-position: 70%;
  }

  &.discovery-focus-area {
    background-position: 70%;
  }

  &.assistance-focus-area {
    background-position: 65%;
  }

  &.visuals-focus-area {
    background-position: 65%;
  }
}

.focus-area-nav-image {
  width: 50px;
  height: auto;
  display: block;
  margin: 60px auto 20px auto;
}

.focus-area-top {
  margin-bottom: $normalDistance;
}

.blue-column {
  background-color: blue;
  height: 100px;
}

.green-column {
    height: 100px;
    background-color: green;
}


@media (max-width: $large-screens) {
  .focus-area-area {
    width: 100%;

    &.open {
      height: auto;
      margin-top: 0;
    }
  }

  .focus-area-container {
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    padding-bottom: 200px;

    .focus-area-title {
      font-size: 42px;
      font-weight: 600;
    }
  }

  .focus-area-nav-box {
    width: 100%;
    max-width: none;
    height: auto;
    margin: 0 0 10px 0;

    .focus-area-nav-image {
      display: inline-block;
      margin: 20px;
      width: 30px;
      height: auto;
    }
  }
}