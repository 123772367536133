@import 'shared';

.App, .lmm, .give-me-fonts, .modal-dialog {
  font-family: 'Rubik', sans-serif;
  color: $textColor1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
