@import '../shared';

.more-resources-progress-bar {
  height: 8px;
  width: 25%;
  margin-right: 10px;
  background-color: $purple3;
  display: inline-block;
  cursor: pointer;
  transition: all 0.35s;

  &.on {
    background-color: $purple1;
  }

  &:hover {
    background-color: $purpleHover;
  }

}

.more-resources-box-container {
  width: 240px;
  margin-right: 20px;
  display: inline-block;
  vertical-align: text-top;
}

.resources-photo {
  width: 100%;
  height: auto;
}

.learn-more {
  color: $purple1;
}

.learn-more-arrow {
  margin-left: 10px;
  width: 18px;
  height: auto;
}

.resources-scroll-within {
  width: 100%;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

}

.horizontal-container {
  width: 3200px;
}

.resources-link {
  color: $purple1;
  transition: all 0.35s;

  &:hover {
    text-decoration: none;
    color: $purpleHover;
  }

}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: $xtra-small-screens) {
  .more-resources-progress-bar-container {
    display: none;
  }

  .more-resources.light-background-section {
    padding-top: 100px;
  }
}