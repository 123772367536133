@import '../shared';

.home {
  .page-top {
    padding-top: 100px;
  }
}

.big-image {
  width: 100%;
  height: auto;
}

.home-title-row {
  margin-bottom: 30px;
}


.home .home-second-person-row {
  margin-top: 200px;
}

.home-big-image {
  margin-bottom: -122px;
  // margin-top: -100px;
  opacity: 0;
  z-index: 10;
}

.home-top-squiggles {
  left: 0;
  top: 0;
}

.home-lady-squiggle-container {
  position: absolute;
  top: 300px;
  right: 10px;

  .home-lady-squiggle {
    width: 100%;
    max-width: 172px;
    height: auto;
  }
}

.home-guy-squiggle-container {
  position: absolute;
  top: 90%;
  right: 10%;

  .home-guy-squiggle {
    width: 45px;
    height: auto;
  }
}

.guy-looking-at-phone {
  margin-top: -160px;
}

.home-video-container {
  // padding-top: 40px;
}

.our-mission-title {
  margin-top: 40px;
}

.purple-section {
  padding: 100px 0;
  background-color: #D7D2FB;
  position: relative;
}

.uphill-image {
  position: absolute;
  max-width: 1440px;
  bottom: 0px;
  z-index: 10;
  right: 0;
}

.orange-circle {
  position: absolute;
  top: 40%;
  left: 50%;
}

.purple-back {
  position: relative;
}

// .big-home-parallax {
//   position: absolute;
//   top: 0%;
//   left: 0%;
// }

.big-home-parallax {
  position: absolute;
  top: 100px;
  left: 0px;
  right: 0;
  opacity: 1;
  z-index: 20;
  text-align: center;
}

.parallax-image {
  position: absolute;
} 

.home-video-squiggle {
  position: absolute;
  left: -400px;
  bottom: -300px;
}

.hill-squiggle {
  position: absolute;
  top: -100px;
  right: 20%;
  width: 80px;
  height: auto;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: $large-screens) {
  .home {
    .big-image {
      margin-top: 30px;
    }
  }

  .home .home-second-person-row {
    margin-top: 100px;
  }

  .our-mission-title {
    margin-top: 0px;
  }

  .orange-circle {
    width: 200px;
    bottom: 0;
  }

  .guy-looking-at-phone {
    margin-top: 0px;
  }

  .uphill-image {
    bottom:0;
  }

  .purple-section.hill {
    padding-bottom: 0px;
    padding-top: 0;

    .above-uphill {
      padding-top: 100px;
    }

    .explore-opps-button {
      margin-bottom: 200px;
    }
  }


  .home-lady-squiggle-container {
    top: 650px;
    right: 10px;

    .home-lady-squiggle {
      width: 70px;
    }
  }

  .home-guy-squiggle-container {
    top: 800px;

    .home-guy-squiggle {
      width: 30px;
      height: auto;
    }
  }
}


/* small */
@media (min-width: 0px) and (max-width: 575px) {

  .big-home-parallax {
    top: -100px;
  }

  .home-big-image {
    margin-bottom: -86px;
    width: 350px;
    opacity: 0;
    z-index: 10;
  }

  .home-top-squiggles {
    width: 350px;
    left: 0;
    top: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1440px) {
  .home-big-image {
    margin-bottom: -86px;
    width: 1000px;
    opacity: 0;
    z-index: 10;
  }

  .home-top-squiggles {
    width: 1000px;
    left: 0;
    top: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 768px) {  
  .home-big-image {
    margin-bottom: -44px;
    opacity: 0;
    z-index: 10;
  }  
}

@media (min-width: 768px) and (max-width: 992px) {
  .home-big-image {
    margin-bottom: -60px;
    opacity: 0;
    z-index: 10;
  } 
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199px) { 
  .home-big-image {
    margin-bottom: -80px;
    opacity: 0;
    z-index: 10;
  }
}

@media (min-width: 1300px) {
  .orphan-word {
    display: block;
  }

}