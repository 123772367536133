// @import url('https://fonts.googleapis.com/css?family=Rubik:wght@300;400;500;600;700&display=swap');



/////// VARIABLES //////////
///////////////////////
///////////////////////
$purple1: #6452F6;
$purple2: #F7F6FE;
$purple3: #B1A8FA;
$purple4: #443E8F;
$purple5: #F0EEFE;
$purpleHover: #1D1A45;
$normalDistance: 100px;
$distance2: 200px;
$textColor1: #39495C;
$whiteHover: #FFDC84;


/////// WIDTHS //////////
///////////////////////
////////////////////////

$xtra-small-screens: 575px;
$small-screens: 576px;
$medium-screens: 768px;
$large-screens: 992px;
$extra-large-screens: 1200px;

// $xtra-small-screens: 992px;



/////// FONTS //////////
///////////////////////
///////////////////////
.text-1 {
  font-size: 76px;
  font-weight: 400;
  line-height: 125%;
}

.text-1-5 {
  font-size: 60px;
  font-weight: 500;
}

.text-2 {
  font-size: 42px;
  font-weight: 500;

  &.regular {
    font-weight: 500;
  }
}

.text-3 {
  font-size: 32px;
  font-weight: 500;

  &.regular {
    font-weight: 400;
  }
}

.text-35 {
  font-size: 24px;
  font-weight: 600;

  &.regular {
    font-weight: 400;
  }
}

.text-4 {
  font-size: 26px;
  font-weight: 500;

  &.regular {
    font-weight: 400;
  }
}

.text-5 {
  font-size: 16px;
  font-weight: 500;

  &.medium {
    font-weight: 500;
  }

  &.regular {
    font-weight: 400;
  }
}

.text-5-1 {
  font-size: 16px;
  font-weight: 400;
}

.text-5-5 {
  font-size: 14px;
  font-weight: 400;
}

.text-6 {
  font-size: 12px;
  font-weight: 400;

  &.medium {
    font-weight: 400;
  }
}

.text-7 {
  font-size: 12px;
  font-weight: 500 !important;
}



/////// SHARED STYLES //////////
///////////////////////
///////////////////////

strong {
  font-weight: 500;
}

.lmm {
  a {
    transition: all 0.35s;

    &:hover {
      text-decoration: none;
    }
  }
}

.relative {
  position: relative;
}

.video-modal {
  // width: 90%;
  // margin-right: auto;
  // margin-left: auto;
  .modal-content {
    height: 100%;
  }
}

.youtube-container {
  height: 100%;
}

.grey-section {
  background-color: $purple5;
}

.grey-section.home-video-container {
  background-color: $purple2;
}

.purple-button {
  margin-top: 40px;
  padding: 20px 40px;
  background-color: $purple1;
  color: white;
  display: inline-block;
  cursor: pointer;
  border-radius: 2px;
  font-weight: 500;
}

.page-top {
  padding-top: 200px;
  background-color: $purple1;
  color: white;
  text-align: center;
  position: relative;
}

.opportunities, .tools, .build-empathy, .get-inspired, .focus-challenge, .about {
  .page-top {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 40%;
  }
}

.opportunities .page-top {
  background-position: 40%;
}

.tools .page-top {
  background-position: 40%;
}

.page-top-space {
  margin-top: 140px;
  // overflow: hidden;
}

.margin-bottom-me {
  margin-bottom: 60px;
}

.margin-top-me {
  margin-top: 60px;
}

.download-help-text {
  max-width: 500px;
  margin: 0 auto;
}

.margin-top-me-none-mobile {
  margin-top: 60px;
}

.margin-top-me-big {
  margin-top: $normalDistance;
}

.margin-bottom-big {
  margin-bottom: $normalDistance;
}

.margin-bottom-me-small {
  margin-bottom: 30px;
}

.standard-section {
  margin-top: $distance2;
}

.hide-overflow {
  overflow: hidden;
}

.normal-section {
  margin-top: $normalDistance;
  margin-bottom: $normalDistance;

  &.digital-tools {
    margin-bottom: 0;
  }

  &.less-top-margin {
    // margin-top: 0;
  }

}

.text-title-margin-bottom {
  margin-bottom: 30px;
}

.text-title-margin-bottom-small {
  margin-bottom: 10px;
}

.invisible {
  visibility: hidden;
}

.bold-text {
  font-weight: 500;
}

.privacy-text {
  margin-top: 10px;
  clear: both;
  transition: all 0.5s;

  &.privacy-in-popup {
    margin-top: 20px;
  }
  
  a {
    color: $purple1;
    text-decoration: underline;
  }
}

.thin-text {
  max-width: 300px;
}

.content-title {
  font-size: 12px;
  font-weight: 500;
  margin: 10px 0 20px 0;
}

.content-block {
  margin-bottom: 20px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  text-align: left;
  align-items: center;
}

.focus-areas-container {
  .flex-row {
    align-items: flex-start;
  }
}

.creative-commons {
  margin-top: 20px;
  font-size: 10px;
  margin-top: 40px;
}

.flex-column {
  flex: 1;
  padding-right: 20px;
}

.download-tool-button {
  background-color: $purple1;
  padding: 0px;
  color: white;
  cursor: pointer;
  max-width: 280px;
  transition: all 0.5s;
  border-radius: 2px;

  &:hover {
    background-color: $purple4;
  }

  &.anchor-tag:hover {
    .download-button-text {
      color: white !important;
    }
  }

  .download-button-text {
    margin-left: 20px;
    font-weight: 500;
  }
  
  .download-icon {
    background-color: #5146B8;
    width: 50px;
    max-width: 50px;
    min-height: 50px;
    background-repeat: no-repeat;
    background-position: 20px center;
    background-size: 14px 21px;
  }

}

.button1 {
  margin-top: $normalDistance;
  padding: 20px 40px;
  background-color: $purple1;
  color: white;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  transition: all 0.35s;
  font-weight: 500;

  &.closed {
    visibility: hidden;
  }

  &.explore-opps-button {
    margin-bottom: $normalDistance;
  }

  &:hover {
    color: white;
    text-decoration: none;
    background-color: $purpleHover;

  }
}

.green-button {
  background-color: #35B9A1;
  background-repeat: no-repeat;
  background-position: 20px center;
  border-radius: 2px;
  padding-left: 60px;
}

.explore-tools-button {
  margin-top: 40px;
  margin-bottom: 60px;
}

.above-uphill {
  position: relative;
  z-index: 20;
}

@media only screen and (min-width : 1200px) {
  .container { max-width: 1440px; }
}

.light-background-section {
  background-color: $purple2;
  padding-top: $normalDistance;
  padding-bottom: $normalDistance;
}

.modal-content {
  background-color: #6452F6;
  color: white;
  text-align: center;
  border: 0;
  border-radius: 2px;
}

.modal-footer {
  border-top: 0px solid white;
}

.download-button {
  padding: 20px;
  color: white;
  width: 300px;
  margin: 0 auto 100px auto;
  background-color: #443E8F;
  cursor: pointer;
  transition: all 0.35s;
  border: 0px;
  border-radius: 2px;
  font-weight: 500;

  &:hover {
    background-color: #1F1B48;
  }
}

.universal-input {
  padding: 20px;
  width: 500px;
  border: none; /* <-- This thing here */
  border: solid 1px #ccc;
  border-radius: 2px;
  margin-left: auto;
  margin-right: auto;
  height: 60px;
  display: block;
}

.sprint-box-container {
  max-width: 570px;
  min-width: 500px;
  min-height: 500px;
  margin: 60px auto;
  position: relative;
  cursor: pointer;
  display: block;
  z-index: 200;
  margin-bottom: -80px;
}

.sprint-box-image {
  width: 100%;
  height: auto;
}

.sprint-box-back {
  position: absolute;
  top: 40px;
  left: 0;
}

.sprint-box-cards {
  position: absolute;
  width: 80%;
  top: 40%;
  left: 10%;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  &.higher {
    top: -50px;
  }
}

.sprint-box-front {
  position: absolute;
  top: 147px;
  left: 16px;
  width: 95%;
}

.error-message {
  color: pink;
  margin-bottom: -30px;
  opacity: 0;
  transition: all 0.35s;

  &.download-button-error {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &.show {
    opacity: 1;
  }
}

.email-form-container {
  margin-top: 32px;
  display: block;

  &.hide {
    display: none;
  }
}

.email-thank-you {
  display: none;
  transition: all 0.35s;
  margin-top: 90px;

  &.show {
    display: block;
  }
}

.form-input {
  padding: 20px;
  width: 300px;
  margin-right: 20px;
  border: none; /* <-- This thing here */
  border:solid 1px #ccc;
  border-radius: 2px;
} 

.close-modal-button {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.display-none {
  display: none;
}

.top-title {
  // margin-bottom: -100px;
}


@media (max-width: $xtra-small-screens) {
  .text-1 {
    font-size: 42px;
  }

  .margin-top-me-none-mobile {
    margin-top: 0px;
  }

  .text-35 {
    font-size: 18px;
    font-weight: 600;
  }

  .universal-input {
    width: 100%;
  }

  .mobile-no-right-padding {
    padding-right: 0;
  }

  .mobile-no-left-padding {
    padding-left: 0;
  }

  .margin-bottom-mobile {
    margin-bottom: 80px;
  }

  .video-modal {
    .modal-content {
      top: 100px;
    }
  }

  .text-2 {
    font-size: 32px;
  }

  .text-3 {
    font-size: 24px;
  }

  .text-4 {
    font-size: 18px;
  }

  .button1 { 
    width: 100%;
    text-align: center;
  }

  .email-form-container {
    margin-bottom: 40px;
    margin-top: 0;
  }

  .join-button {
    margin-top: 20px;
  }

  .contain-on-mobile {
    overflow: hidden;
  }

  .sprint-box-container {
    max-width: 400px;
    min-width: 300px;
    min-height: 400px;
    z-index: 20;
    height: auto;
    margin: 0px auto 0px auto;
    position: relative;
    cursor: pointer;
    display: block;
    overflow: hidden;
    padding-top: 100px;
  }

  .sprint-box-front {
    position: absolute;
    top: 116px;
    left: 10px;
    width: 95%;
  }

  .sprint-box-back {
    position: absolute;
    top: 60px;
    left: 0;
  }

  .page-top {
    padding-top: 100px;
    background-position: top;
  }

  .opportunities{
    .page-top {
      background-size: contain;
      background-position: 0 144px;
      background-repeat: no-repeat;
    }
  }

  .home-top-image-container {
    // min-height: 200px;

    .home-big-image {
      margin-top: 0;
      margin-bottom: -30px;
    }
  }

  .error-message {
    margin-bottom: 0;  
  }

  .normal-section {
    &.digital-tools {
      margin-bottom: 0;
    }
  }
}

@media (max-width: $large-screens) {
  .mobile-no-right-padding {
    padding-right: 0;
  }


}


